import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { ModalController, NavParams } from '@ionic/angular';
import { DxDiagramComponent } from 'devextreme-angular';
import * as $ from 'jquery';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-transfer-tickets',
	templateUrl: './transfer-tickets.component.html',
	styleUrls: ['./transfer-tickets.component.scss'],
})
export class TransferTicketsComponent implements OnInit {
	@ViewChild(DxDiagramComponent, { static: true }) diagram: DxDiagramComponent;

	account: any;

	floorDefaultConfs: any = {
		"page": {
			"width": 8391,
			"height": 11907,
			"pageWidth": 8391,
			"pageHeight": 11906,
			"pageLandscape": false,
			"gridSize": 180,
			"snapToGrid": true
		},
		"connectors": [
		],
		"shapes": [
		]
	}

	floorplan: any = false;
	floorplans: any = [];

	slideOptions = {
		slidesPerView: 'auto',
		zoom: false,
		autoplay: false,
		loop: false,
		spaceBetween: 16
	};
	orders: any;

	constructor(
		public auth: AuthService,
		public api: ApiService,
		public components: ComponentsService,
		public router: Router,
		public modalController: ModalController,
		public navParams: NavParams,
		public pos: PosService
	) {
		this.orders = navParams.get('orders');
	}

	ngOnInit() {
		this.components.showLoader().then(async () => {
			this.account = await (await Preferences.get({ key: 'account' })).value;

			this.api.getRef(`accounts/${this.auth.account}/floorplans`).ref
				.where('branch_key', '==', this.auth.branch)
				.orderBy('creation_date')
				.onSnapshot((snapshots: any) => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let floorplan = element.doc.data();
							floorplan.$key = element.doc.id;

							if (floorplan.floorplan) {

								if (!this.floorplan) {
									this.floorplan = floorplan;
									this.renderFloorMap();
								}

								this.floorplans.push(floorplan);
							}
						} else if (element.type == 'modified') {

							let floorplan = element.doc.data();
							floorplan.$key = element.doc.id;

							const found = this.floorplans.findIndex(_element => _element.$key == element.doc.id);
							this.floorplans[found] = floorplan;
						}
					});
				}, err => {
					console.log(err);
				});
		})
	}

	ionViewDidEnter() {
		this.renderFloorMap();
		this.diagram.onContentReady.subscribe(data => {
		})
	}

	renderFloorMap() {
		if (this.floorplan) {
			this.diagram.fullScreen = true;
			this.diagram.simpleView = true;
			this.diagram.fullScreen = false;
			this.diagram.readOnly = true;
			this.diagram.showGrid = false;

			let counter = 0;
			this.floorplan.floorplan.shapes.forEach(element => {

				let type = element.type.split("_")[0];
				if (type == 'furniture') {
					this.api.getRef(`accounts/${this.auth.account}/orders`).ref
						.where('branch_key', '==', this.auth.branch)
						.where('table', '==', String(element.text))
						.limit(1)
						.get()
						.then(data => {
							if (data.empty) {
								element['styleText'] = {
									fill: '#000000'
								}
							} else {
								element['styleText'] = {
									fill: '#000'
								}
								if (!element.type.split('_').find(tag => tag === 'busy')) {
									element.type = element.type + '_busy';
								}
								element['active'] = true;
								element.text = element.text;
							}
							counter++
							if (counter >= this.floorplan.floorplan.shapes.length) {
								this.renderMap();
							}

						}, err => {
							console.log(err);
						});
				} else {
					counter++;
					if (counter >= this.floorplan.floorplan.shapes.length) {
						this.renderMap();
					}
				}
			});

		}
	}

	renderMap() {
		this.diagram.instance.import(JSON.stringify(this.floorplan.floorplan))
		setTimeout(() => {
			$('.dxdi-main .shape text').each(function (index, element) {

				if ($(element).attr('style') == 'fill: rgb(255, 255, 255);') {
					$(element).parent().addClass('busy');
				} else {
					$(element).parent().addClass('empty');
				}

			});
			this.components.dismissLoader();
		}, 100);
	}

	selectionChangedHandler(e) {
		if (e.items[0] !== undefined) {
			this.components.showLoader().then(() => {
				let element = e.items[0];
				let type = element.type.split("_")[0];

				if (type == 'furniture') {
					this.renderMap();
					this.api.getRef(`accounts/${this.auth.account}/orders`).ref
						.where('branch_key', '==', this.auth.branch)
						.where('table', '==', String(element.text))
						.where('table_area', '==', String(this.floorplan.name))
						.limit(1)
						.get()
						.then(async snapshots => {
							if (snapshots.empty) {
								if (!this.navParams.get('orders').includes(false)) {
									this.pos.table = String(element.text);
									this.pos.table_area = this.floorplan.name;
									this.api.changeOrdersTables(this.orders).then(data => {
										this.components.dismissLoader();
										this.modalController.dismiss(true);
									}, err => {
										console.log(err);
									})
								} else {
									this.pos.table = String(element.text);
									this.pos.table_area = this.floorplan.name;
									this.components.dismissLoader();
									this.modalController.dismiss();
								}
							} else {
								this.components.showToast('Mesa no disponible', 'error');
								this.components.dismissLoader();
							}

						}, err => {
							console.log(err);
						})
				}

			})
		}
	}

	selectFloorPlan(floorplan) {
		this.floorplan = floorplan;
		this.renderFloorMap();
		this.pos.table_area = this.floorplan.name;
	}
}
