import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { PrintService } from 'src/app/services/print/print.service';
import { AuthService } from './../../../services/auth/auth.service';
import { ComponentsService } from './../../../services/components/components.service';
import { TicketDetailsComponent } from '../../shared/ticket-details/ticket-details.component';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PosService } from 'src/app/services/pos/pos.service';
import { TranslateConfigService } from 'src/app/services/language/languages.service';




@Component({
	selector: 'app-success-payment',
	templateUrl: './success-payment.component.html',
	styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent implements OnInit {

	ticket_key: any;
	ticket: any;
	email: any;
	pending: any;
	discounts: any = [];
	total_discount: any = 0;
	iva_discount: any = 0;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		public api: ApiService,
		public auth: AuthService,
		public modalController: ModalController,
		public print: PrintService,
		private platform: Platform,
		public pos: PosService,
		public langService: TranslateConfigService
	) {
		let params = this.navParams.data;
		this.ticket_key = params.ticket_key;
	}

	ngOnInit() {

		this.api.getDocument(`accounts/${this.auth.account}/tickets`, this.ticket_key)
			.then((snapshots: any) => {
				this.ticket = snapshots;
				this.ticket.creation_date = this.ticket.creation_date.toDate();
				this.ticket.completed_date = this.ticket.completed_date.toDate();
				this.ticket.items = [];

				if (this.ticket.iva_rete == undefined) {
					this.ticket.iva_rete = 0;
				}

				if (this.ticket.discount > 0) {
					this.api.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
						.where(`ticket_key`, '==', this.ticket.$key)
						.get()
						.then((snapshots: any) => {
							snapshots.forEach(element => {
								let discount = element.data();
								discount.$key = element.id;
								discount.creation_date = discount.creation_date.toDate();

								if (this.ticket.exempt == 0) {
									this.total_discount = discount.total_discount + discount.iva_discount;
									this.iva_discount += discount.iva_discount;
								} else {
									this.total_discount = discount.total_discount;
								}

								this.discounts.push(discount);
							});
						})
				}

				this.ticket.payments = [];

				if (this.ticket.status == 'credit') {
					this.pending = this.ticket.total;
					this.getPending();
				} else {
					this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
						.where('ticket_key', '==', this.ticket.$key)
						.get()
						.then((snapshots: any) => {
							snapshots.forEach(element => {
								let payment = element.data();
								payment.$key = element.id;
								payment.creation_date = payment.creation_date.toDate();
								this.ticket.payments.push(payment);

							});
						}, err => {
							console.log(err);
						})
				}

				this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
					.where('ticket_key', '==', this.ticket.$key)
					.get()
					.then((snapshots: any) => {
						snapshots.forEach(element => {
							let item = element.data();
							item.$key = element.id;
							this.ticket.items.push(item);
						});
					}, err => {
						console.log(err);
					});

			})
	}

	async openTicketDetail(ticket) {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;
			console.log(ticket);

			const modal = await this.modalController.create({
				component: TicketDetailsComponent,
				cssClass: 'full-modal',
				componentProps: {
					ticket: ticket
				}
			});

			modal.onDidDismiss().then((data) => {
				if (data.data) { }
			});

			return await modal.present().then(() => { this.components.isModalPresent = false; })
		}
	}

	getPending() {
		this.ticket.payments = [];

		this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
			.where('ticket_key', '==', this.ticket.$key)
			.get()
			.then((snapshots: any) => {
				snapshots.forEach(element => {
					let payment = element.data();
					payment.$key = element.id;
					payment.creation_date = payment.creation_date.toDate();
					this.ticket.payments.push(payment);
				});

				let pending = Number(this.ticket.total);
				this.ticket.payments.forEach(element => {
					pending -= element.amount;
				});

				this.pending = pending;
				if (this.pending <= 0) {
					this.ticket.status = 'completed';
				}

			}, err => {
				console.log(err);
			})
	}

	printTicket() {

		if (!this.auth.license.membership.features.tickets.print) {
			this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podrás imprimir ordenes.', ['Aceptar']);
		} else {

			let ticket = JSON.parse(JSON.stringify(this.ticket));

			ticket.items.forEach(item => {
				if (ticket.exempt > 0) {
					item.price = item.price + item.hidden_taxes;
					item.price = (item.price) / item.quantity;
				}
			});
			ticket.completed_date = this.ticket.completed_date;
			ticket.creation_date = this.ticket.creation_date;
			ticket.fiscal = this.ticket.fiscal;

			if (ticket.iva_discount == undefined) {
				ticket.iva_discount = 0;
			}

			if (this.ticket.iva_discount == undefined) {
				this.ticket.iva_discount = 0;
			}

			if (this.platform.is('cordova') || this.print.quanto_prints) {
				this.print.printTicket(this.ticket);
			} else {
				this.print.pdfTicket(this.ticket);
			}
		}
	}

}
