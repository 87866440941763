import { EventEmitter, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { isEqual } from 'lodash';
import { ComponentsService } from '../components/components.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class PosService {

	public AddItemEvent: EventEmitter<number> = new EventEmitter();
	public UpdateItemEvent: EventEmitter<number> = new EventEmitter();
	public cancelOrderEvent: EventEmitter<number> = new EventEmitter();
	public removeItems: EventEmitter<number> = new EventEmitter();
	public updateOrder: EventEmitter<number> = new EventEmitter();
	public changeTypeEvent: EventEmitter<number> = new EventEmitter();
	public changeFiscalType: EventEmitter<number> = new EventEmitter();

	items: any = [];
	discounts: any = [];
	promotions: any = [];
	client: any = false;
	delivery: any = false;
	note: any = false;
	splitted = 0;
	splitted_payments = 0;
	items_count: any = 0;
	ticket_open: any = false;
	mens: any = 0;
	womens: any = 0;
	childrens: any = 0;
	type: any = '';
	table: any = '';
	table_area: any = '';
	tax_receipt: any = { type: 'ticket' };
	exempt: any = false;
	order: any;
	scanner_open: any = false;

	constructor(
		public db: AngularFirestore,
		public components: ComponentsService,
		public alertController: AlertController,
		public router: Router
	) {
	}

	addItem(item) {

		if (this.delivery && (item.delivery_prices && item.delivery_prices.length > 0)) {
			const delivery_found = item.delivery_prices.findIndex(element => element.delivery_key == this.delivery.$key);
			if (delivery_found > -1) {
				item.price = Number(item.delivery_prices[delivery_found].price);
			}
		}

		item.temp_price = Number(item.price);
		//Funciona para guardar el precio real antes de aplicarle una promo o descuento.
		item.real_price = Number(item.price);

		item = this.checkPromotion(item);

		if (item.is_variant) {
			item.category = item.parent.category;
		}

		item.is_promotion = false;
		item.kds_type = '';
		item.price = Number(this.components.fixed(item.price));

		const found = this.items.findIndex(element => element.item_key == item.item_key && element.notes == item.notes && element.real_price == item.real_price && element.have_promotion == item.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers) && item.have_discount == element.have_discount);

		if (found > -1) {
			this.items[found].quantity += item.quantity;
			this.items[found].hidden_taxes = this.components.fixed(item.hidden_taxes * this.items[found].quantity);
		} else {
			item.id = this.items.length + 1;
			item.hidden_taxes = this.components.fixed(item.hidden_taxes * item.quantity);
			this.items.push(item);
		}

		this.items_count += item.quantity;
		this.AddItemEvent.emit(item);
	}

	updateItem(item, new_note = false) {
		const found = this.items.findIndex(element => item.id == element.id);
		if (new_note) item.notes = new_note;
		this.items[found] = item;
		this.UpdateItemEvent.emit();
	}

	updateItemPrice(item) {
		const found = this.items.findIndex(element => element.id == item.id);
		this.items[found] = item;
		this.items[found].have_promotion = false;
		this.items[found].promotion = '';
		this.items[found].temp_price = item.price;
		this.checkPromotion(item);
		this.UpdateItemEvent.emit();
	}

	addMiscellaneous(item) {
		this.items.push(item);
		this.AddItemEvent.emit();
	}

	removeItem(item) {
		const found = this.items.findIndex(element => element.id == item.id);
		this.items_count -= this.items[found].quantity;
		this.items.splice(found, 1);
		this.components.showToast('Producto eliminado correctamente.');
		if (item.have_promotion && item.promotion.usage_limits) {
			const found_promo = this.promotions.findIndex(_element => _element.$key == item.promotion.$key);
			this.promotions[found_promo].usage -= item.quantity;
		}
		this.removeItems.emit(item);
	}

	cancelOrder(order) {
		return new Promise(async (resolve, reject) => {
			if (this.items.length > 0 || this.client) {
				const alert = await this.alertController.create({
					header: 'Tienes datos sin guardar',
					message: `¿Seguro que deseas salir sin guardar los items?. Esta acción no se puede revertir.`,
					buttons: [
						{
							text: 'Cancelar',
							role: 'cancel'
						},
						{
							text: 'Eliminar',
							role: 'destructive',
							handler: () => {
								if (order == undefined) {
									this.items.forEach(element => {
										if (element.have_promotion && element.promotion.usage_limits) {
											const found_promo = this.promotions.findIndex(_element => _element.$key == element.promotion.$key);
											this.promotions[found_promo].usage -= element.quantity;
										}
										this.removeItems.emit(element);
									});
									this.cancelOrderEvent.emit();
									this.resetAll();
								} else {
									this.items.forEach(element => {
										if (element.have_promotion && element.promotion.usage_limits) {
											const found_promo = this.promotions.findIndex(_element => _element.$key == element.promotion.$key);
											this.promotions[found_promo].usage -= element.quantity;
										}
										this.removeItems.emit(element);
									});

									this.resetAll();
								}
								resolve(true);
							}
						}
					],
				});
				await alert.present();
			} else {
				this.resetAll();
				this.cancelOrderEvent.emit();
				this.router.navigate(['pos']);
				resolve(true);
			}
		})
	}

	clearOrder(order) {
		if (order == undefined) {

			this.items = [];
			this.discounts = [];
			this.client = false;
			this.delivery = false;
			this.note = false;
			this.splitted = 0;
			this.splitted_payments = 0;
			this.items_count = 0;
			this.ticket_open = false;
			this.mens = 0;
			this.womens = 0;
			this.childrens = 0;
			this.type = '';
			this.table = '';
			this.table_area = '';
			this.tax_receipt = { type: 'ticket' };
			this.exempt = false;
			this.order;
			this.scanner_open = false;

			this.cancelOrderEvent.emit();
		} else {

			this.note = '';
			this.items = [];
			this.items_count = 0;
			this.client = false;
			this.discounts = [];
			this.tax_receipt = { type: 'ticket' };
			this.exempt = false;
			this.ticket_open = false;
			this.removeItems.emit();
		}
	}

	changeFiscal(fiscal) {
		this.changeFiscalType.emit(fiscal);
	}

	updateOrderTicket(order) {
		this.updateOrder.emit(order);
	}

	addPromotion(promo) {
		this.items.push(promo);
		promo.total_taxes = 0;
		promo.hidden_taxes = 0;
		promo.temp_price = Number(promo.price);
		promo.real_price = Number(promo.price);

		promo.x_items.concat(promo.y_items).forEach(item_promo => {

			item_promo.temp_price = Number(item_promo.price);
			item_promo.real_price = Number(item_promo.price);

			if (item_promo.taxes_item) {
				item_promo.taxes_item.forEach(tax => {
					this.components.getTaxes(item_promo, tax, promo);
				});
			}
		});

		this.AddItemEvent.emit();
	}

	checkPromotion(item) {
		let promo_added = false;

		this.promotions.forEach((promotion, index) => {
			if (!promo_added) {
				let valid = true;

				if (!item.have_promotion) {
					item.temp_price = item.price;
				}
				//Validamos la promoción y vemos si aplica
				if (promotion.clients.length > 0) {
					if (this.client) {
						const found_client = promotion.clients.findIndex(_element => _element == this.client.$key);
						if (found_client < 0) valid = false;
					} else {
						valid = false;
					}
				}

				if (new Date() < promotion.start_date || new Date() > promotion.end_date) {
					valid = false;
				}

				if (promotion.usage_limits) {
					if ((promotion.usage_limits - promotion.usage) == 0) {
						valid = false;
					} else {
						this.promotions[index].usage += item.quantity;
					}
				}

				if (promotion.usage_order_limits) {
					let usage_order_limits = 1;
					this.items.forEach(element => {
						let item = element;
						if (item.have_promotion && (item.promotion.$key === promotion.$key)) {
							usage_order_limits += (item.quantity);
						}
					});

					if (this.order) {
						this.order.items.forEach(element => {
							let item = element;
							if (item.have_promotion && (item.promotion.$key === promotion.$key)) {
								usage_order_limits += (item.quantity);
							}
						});
					}

					if (usage_order_limits > promotion.usage_order_limits) {
						valid = false;
					}
				}

				if (valid) {
					if (!item.have_promotion) {
						if (promotion.apply_all) {
							item.have_promotion = true;
							item.promotion = promotion;

							if (promotion.percentage) {
								let price = item.price * (promotion.percentage / 100);
								item.price = item.price - price;
							} else if (promotion.amount) {
								let price = item.price - promotion.amount;
								item.price = price;
								if (item.price < 0) item.price = 0;
							}

							if (item.taxes_item) {
								item.taxes_item.forEach(tax => {
									this.components.getTaxes(item, tax);
								});
							}

							promo_added = true;
						} else {
							let found_item;
							if (item.is_variant) {
								found_item = promotion.items.findIndex(element => element.key == (element.type == 'category' ? item.parent.category.$key : (item.is_variant ? item.parent_key : item.item_key)));
							} else {
								found_item = promotion.items.findIndex(element => element.key == (element.type == 'category' ? item.category.$key : (item.is_variant ? item.parent_key : item.item_key)));
							}

							if (found_item > -1) {
								item.have_promotion = true;
								item.promotion = promotion;
								if (promotion.amount) {
									item.price = item.price - promotion.amount;
									if (item.price < 0) item.price = 0;
								} else {
									let price = item.price * (promotion.percentage / 100);
									item.price = item.price - price;
								}
								if (item.taxes_item) {
									item.taxes_item.forEach(tax => {
										this.components.getTaxes(item, tax);
									});
								}
								promo_added = true;
							}
						}

						item.temp_price = item.price;
					}
				} else {
					delete item.promotion;
					item.have_promotion = false;
					item.price = item.temp_price;
				}

			}
		});
		return item;
	}

	addClient(client) {
		this.client = client;
		this.items.forEach(item => {
			if (!item.have_promotion && !item.is_promotion) {
				this.checkPromotion(item);
			}
		});
		console.log(this.items);

		this.UpdateItemEvent.emit();
	}

	addDelivery(delivery) {
		this.delivery = delivery;
	}

	removeClient() {
		this.client = false;

		this.items.forEach(item => {
			if (item.have_promotion) {
				this.checkPromotion(item);
			}
		});
		this.UpdateItemEvent.emit();
	}

	addDiscount(discount, item = undefined) {
		if (item) {
			const found = this.items.findIndex(element => element.item_key == item.item_key && element.notes == item.notes && element.real_price == item.real_price && element.have_promotion == item.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers) && item.have_discount == element.have_discount);
			this.items[found].have_discount = true;
			this.items[found].discount_item = discount;
			this.items[found].price = item.price;
			this.items[found].temp_price = item.price;
		} else {
			this.discounts.push(discount);
		}

		this.AddItemEvent.emit();
	}

	updateDiscount(discount) {
		const found = this.discounts.findIndex(element => element.$key == discount.$key);
		this.discounts[found] = discount;
		this.UpdateItemEvent.emit();
	}

	removeDiscount(discount, item = undefined) {
		const found = this.discounts.findIndex(element => element.$key == discount.$key);

		if (item) {
			const found = this.items.findIndex(element => item.item_key == element.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));
			delete this.items[found].have_discount;
			delete this.items[found].discount_item;
			this.items[found].price = this.items[found].real_price;
			this.items[found].temp_price = this.items[found].price;

		} else {
			this.discounts.splice(found, 1);
		}

		this.components.showToast('Descuento eliminado correctamente.');
		this.UpdateItemEvent.emit();
	}

	resetAll() {
		this.items = [];
		this.discounts = [];
		this.client = false;
		this.delivery = false;
		this.note = false;
		this.splitted = 0;
		this.splitted_payments = 0;
		this.items_count = 0;
		this.ticket_open = false;
		this.mens = 0;
		this.womens = 0;
		this.childrens = 0;
		this.type = '';
		this.table = '';
		this.table_area = '';
		this.tax_receipt = { type: 'ticket' };
		this.exempt = false;
		this.order;
		this.scanner_open = false;
	}

	changeType(type) {
		this.type = type;
		if (this.type != 'tables') {
			this.table = '';
		}
		this.changeTypeEvent.emit();
	}
}
