// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-content {
  background: var(--ion-color-light) !important;
}

h3 {
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tables/number/number.component.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".inner-content {\n  background: var(--ion-color-light) !important;\n}\n\nh3 {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
