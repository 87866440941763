import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AuthService } from './../../../services/auth/auth.service';
import { PrintService } from 'src/app/services/print/print.service';

@Component({
	selector: 'app-return-item',
	templateUrl: './return-item.component.html',
	styleUrls: ['./return-item.component.scss'],
})
export class ReturnItemComponent implements OnInit {

	item: any;
	quantity: any = 1;
	note: any = '';
	message: any = '';
	return_stock: any = false;
	other: any = false;
	ticket: any;
	order: any;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public alertController: AlertController,
		public auth: AuthService,
		public platform: Platform,
		public print: PrintService,

	) {
		this.item = JSON.parse(JSON.stringify(navParams.data.item));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
		this.order = JSON.parse(JSON.stringify(navParams.data.order));
	}

	ngOnInit() { }

	changeQuantity(type) {
		if (type == 'add') {
			if (this.quantity + 1 > this.item.quantity) {
				this.components.showToast('Limite de items para anular');
			} else {
				this.quantity++;
			}

		}
		else if (type == 'minus') {
			if (this.quantity - 1 < 1) {
				this.components.showToast('Para anular debes seleccionar al menos uno');
			} else {
				this.quantity--;
			}

		}
	};

	returnItem() {
		this.components.showLoader('Realizando devolución...').then(() => {
			this.api.voidItem(this.item, this.quantity, this.return_stock, this.note, this.message, this.order).then(async(items) => {
				if(items) this.showModal()
				this.components.dismissLoader();
				this.modalController.dismiss({ item_refunded: true, refund: true });
			});
		});
	}


	async showModal() {
		const alert = await this.alertController.create({
			header: 'Anulación Exitosa.',
			message: '¿Deseas imprimir anulacion?.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Si, imprimir',
					id: 'confirm-button',
					handler: () => this.printReturn()
				}
			]
		});

		await alert.present();
	}

	printReturn() {
		
		const order = this.order;
		order.items = [this.item];
		let amount = 0;
		order.items.forEach(element => {
			amount += element.price * element.quantity;
		});
		order.creation_date = order.creation_date.toDate();
		order.return_user = this.order.user
		order.amount = amount;
		order.note = this.note;
		let total = 0;
		let total_payments = 0;
		let gran_discount = 0;
		let hidden_taxes = 0;
		let taxes = 0;
		let tip = 0;

		let client: any = false;


		if (order.items) {
			order.items.forEach(element => {

				element.price = Number(element.price);

				if (element.is_promotion) {
					let promo = element;
					promo.quantity = 1;
					promo.total_taxes = 0;
					promo.hidden_taxes = 0;

					element.x_items.forEach(element_x => {

						if (element_x.taxes_item) {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;
							element_x.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
									element_x.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_x.price * element_x.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

					element.y_items.forEach(element_y => {
						if (element_y.taxes_item) {
							element_y.total_taxes = 0;
							element_y.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
									element_y.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_y.price * element_y.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

				} else {
					if (element.is_variant) {
						if (element.parent.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
							element.parent.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					} else {
						if (element.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;

							element.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					}
				}

				total += element.price * element.quantity;
			});
		} else {

		}


		if (order.client) {
			client = order.client;
		}

		if (order.type == 'tables') {
			if (this.auth.branch_data.active_tip && !order.tip_removed) {
				if (this.auth.branch_data.include_taxes_tip) {
					tip = ((total * Number(this.auth.branch_data.tip)) / 100);
				} else {
					tip = (((total - (taxes + hidden_taxes)) * Number(this.auth.branch_data.tip)) / 100);
				}
				tip = Math.round(tip * 100) / 100
			} else {
				tip = 0;
			}
		} else {
			tip = 0;
		}

		if (order.discounts) {

			order.discounts.forEach(element => {
				if (element.type == 'percentage') {

					let discount = Number(Number(total * (Number(element.amount) / 100)).toFixed(2));
					element.total_discount = discount;
					total = total - discount;
					gran_discount += discount;
				} else if (element.type == 'fixed') {
					element.total_discount = element.amount;
					total = total - element.amount;
					gran_discount += element.amount;
				}

				if (total < 0) {
					total = 0;
				}
			});
		}
		console.log(order, order.amount, total, tip, gran_discount, true)
		if (this.platform.is('cordova') || this.print.quanto_prints) {
			this.print.printOrder(order, order.amount, total, tip, gran_discount, true);
		} else {
			this.print.pdfOrder(order, order.amount, total, tip, gran_discount, true);
		}
	}

}
